.cards-row .card{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    margin: 20px 12px 0 0;
    max-width: 210px;
    position: relative;
    padding: 7px;
    transition: .6s ease all;
}

.cards-row .card img{
    border-radius: 6px;
}

.all-cards-container .card{
    -webkit-transition: .7s ease transform;
    -o-transition: .7s ease transform;
    transition: .7s ease transform;
}

.card.selected{
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
        transform: scale(1.1);    
    bottom: 11px;
}

.card.zoomed-in{

    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #333333ee;
    z-index: 10000000;
    transform: scale(1);
    padding: 0;
    margin: 0;
    max-width: 10000px;
    transition: none;

}
.card.zoomed-in img{
    position: fixed;
    top: 40px;
    left: 100px;
    transform: scale(1);
    z-index: 10000001;
    height: calc(100vh - 80px);
    width: calc(100vw - 200px);
    object-fit: contain;
}
.card.zoomed-in input[type=checkbox], .card.zoomed-in input[type=radio] {
    display:none;
}

/* .card input[type=checkbox], input[type=radio] {
    position: absolute;
    width: 30px;
    height: 18px;
    left: calc(50% - 15px);
    bottom: 20px;
    box-sizing: border-box;
    accent-color: #79CE67;
} */

.card input[type=checkbox], input[type=radio] {
    display: none;
}

@media (max-height:376px){

    .cards-row .card {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        margin-right: 19px;
        max-width: 80px;
        position: relative;
        padding: 4px 7px;
        margin-top: 16px;
    }
}