.shapes-modes-collapsed{
    position: absolute;
    transition: all 0.2s;
    left: 0;
    top: 158px;
    width: 0;
    height: 0;
    overflow: hidden;
}

.shapes-modes-expanded{
    overflow: hidden;
    transition: all 0.2s;
    position: absolute;
    top: 94px;
    width: 142px;
    height: 100px;
    left: 58px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border-top: 1px solid #F5F3F1;
    border-bottom: 1px solid #F5F3F1;
    border-right: 1px solid #F5F3F1;
    border-radius: 0px 12px 12px 0px;
    flex-wrap: wrap;
}

.shapes-modes-expanded svg{
    margin-right: 10px;
}

.shapes-modes-expanded svg{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: none !important;
}

@media (max-height: 578px){
    .shapes-modes-expanded{
        top: 40px;
        left: 80px;
        padding: 0;
        border-top: 0px;
        border-radius: 0px 0px 12px 12px;
        width: 104px;
        height: 125px;
    }
}