.top-bar-container{
    width: 500px;  
    height: 66px;
    background-color: #FFFFFF;
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.top-bar-container-user{
    width: 100%;  
    background-color: #FFFFFF;
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.logo-bar{
    width: 150px;
    height: 32px;
    margin: 0;
    border-right: 1px solid #F5F5F5;
    border-left: 1px solid #F5F5F5;
    margin-right: 10px;
    margin-left: 14px;
    padding: 0 6px;
}

.top-bar-container button{
    background-color: transparent;
}

.top-bar-container-user .logo-bar{
    width: 150px;
    margin: 0;
    border-right: 1px solid #F5F5F5;
    border-left: 1px solid #F5F5F5;
    margin-right: 10px;
    padding-right: 14px;
}

.top-bar-container-user .session-timing p{
    font-size: 15px;
}

.download{
    margin-left: 8px;
    margin-right: 10px;
}
.lock-icon{
    margin-left: 10px;
    margin-right: 0px;
    cursor:pointer;
}

.users{
    margin-right: 18px;
}

.session-timing{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 131px;
}

.session-timing p{
    margin: 0;
    font-size: 12px;
}

.finish-session{
    width: 165px;
    margin: 0;
    font-size: 14px;
}

.finish-session:hover{
    background-color: #a956c7ab;
}

.next-session-menu{
    position: absolute;
    width: 0;
    overflow: hidden;
    top: 0px;
    left: -90px;
    background-color: #FFF;
    height: 100vh;
    padding: 18px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    transition: 0.3s ease all;
}

.next-session-menu.open{
    display:block;
    width: 86%;
    left: 0;
    margin-left: 18px;
    margin-top: 20px;
    height: 95vh;
}

.next-session-menu.hidden{
    left: -90px;
    width: 0;
}

.next-session-menu-container{
    position: relative;
    height: 96vh;
    overflow: scroll;
}

.next-session-menu ion-header{
    margin-bottom: 40px;
} 

.next-session-menu svg{
    margin-top: 3px;
}

.next-sessions-title{
    display: flex;
    align-items: flex-start;
}

.next-sessions-title ion-text{
    margin-right: 10px;
}

.next-session-menu .sessions-row{
    margin-top: 30px;
    height: 60%;
    overflow: scroll;
}

.next-session-menu .sessions-row.decreased{
    overflow: scroll;
    height: 33%;
}

.next-session-menu .sessions-row ion-row{
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #F5F5F5;
}

.next-session-menu .sessions-row ion-row:last-child{
    border-bottom: none;
}

.next-session-menu .sessions-row p{
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 3px;
}

.next-session-menu .sessions-row .session-name{
    font-weight: 400;
    margin-bottom: 0;
    white-space: nowrap;
}
.next-session-menu .sessions-row .session-name{
    margin-bottom: 7px;
}

.next-session-menu .next-session-link-url{
    display: flex;
    white-space: nowrap;
    padding: 10px;
    background-color: #F5F3F1;
    border-radius: 5px;
}

.next-session-menu .next-session-link-url p{
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    margin-right: 20px;
}

.next-session-menu .sessions-row .copy-message {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.next-session-menu-container ion-button{
    position: absolute;
    width: 85%;
    left: calc(49% - 122px);
    bottom: 35px;
    padding: 0;
}

.next-session-menu .button-hidde{
    display: none;
}

.next-session-modal{
    padding:15px
}

.next-session-modal ion-label{
    margin-right: 20px;
    font-family: 'Ubuntu', 'Open Sans';
    font-weight: 500;
    font-size: 12px;
    margin-top: 10px;
    color: #363636
}

.next-session-modal ion-label:last-child{
    margin-bottom: 20px;
}


@media (max-width: 902px) {
    .next-session-menu-container h1{
        font-size: 20px;
    }

    .clock-next-sessions{
        display: none;
    }

    .next-session-menu-container .sessions-row p{
        font-size: 14px;
    }
}


@media (max-width: 768px) {
    .next-session-menu-container h1{
        font-size: 18px;
    }

    .next-session-menu-container .sessions-row p{
        font-size: 12px;
    }
}

@media (max-width: 498px) {

    .top-bar-container {
        width: 320px;
        height: 66px;
        margin-left: 32px;
    }

    .top-bar-container .collapsed-icon{
        display: none;
    }

    .top-bar-container .logo-bar{
        display: none;
    }
}

@media (max-height: 578px){
    .next-session-menu-container ion-button{
        font-size: 9px;
    }
}

