::placeholder{
    font-family: 'Open Sans';
    font-size: 14px;
}

.new-session-container{
    position: relative;
    width: 70%;
    height: 95vh;
    margin: 20px 16px 0px 0px!important;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    overflow: scroll;
    overflow-x: hidden;
}

.new-session-container-mobile{
    position: relative;
    height: 88vh;
}

.new-session-container h1{
    margin: 44px 0 70px 32px;
}

.title-header{
    margin-bottom: 40px;
}

.new-session-container .form-group{
    display: flex;
    flex-direction: column;
    width: 26%;
    margin-right: 19px;
}

.sessions-form-codes{
    margin-left: 30px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: url(../../../assets/img/icon-calendar.svg) center/80% no-repeat;
    background-size: 80% 80%;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    background: url(../../../assets/img/icon-clock.svg) center/80% no-repeat;
    background-size: 80% 80%;
}

.sessions-form-codes ion-text{
    background-color: #F5F3F1;;
}

.sessions-form-codes p{
    font-weight: 400;
    margin: 0;
    margin-left: 2px;
}

.new-session-form{
    margin-top: 16px;
    /* height: 82vh; */
}

.session-form-codes-row{
    display: flex;
    margin-top: 10px;
}

.new-session-container .new-session-form ion-text{
    width: 20%;
    margin-top: 6px;
    margin-right: 20px;
    display: inline-flex;
    padding: 7px;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 14px;
}
.iconButton{
    cursor: pointer;
}
.new-session-container .form-group.name{
    width: 32%;
}

.input{
    background-color: #F5F3F1;
    border: 1px solid rgba(54, 54, 54, 0.05);
    border-radius: 4px;
    padding: 5px;
    margin-top: 7px;
    font-size: 14px;
    font-family: 'Open Sans';
}

.lineBreak{
    margin: 30px 0 0px 32px;
    background-color: #F5F3F1;
    position: relative;
    width: 62vw;
    height: 1px;
}

.tools-container{
    margin-left: 32px;
}

.new-session-form .tools-container ion-text{
    width: 86%;
}

.tools-container ion-col{
    padding-left: 0;
}

.tools-container svg{
    margin-top: 8px;
    margin-right: 10px;
    transition: .3s ease all;
}

.tools-container svg:active{
    transform: scale(0.9)
}

.active{
    border-radius: 13.2927px;
    border: 2px solid #79CE67;
}

.tools-container .selection{
    margin-top: 20px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
}

.tools-container .selection-icon{
    display: flex;
    align-items: center;
}

.standar-tools,
.personalized-tools{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

ion-text{
    font-family: 'Open Sans';
    font-size: 14px;
}

.personalized-tools ion-img{
    margin-top: 10px;
}

.session-link{
    padding-top: 28px;
    display: flex;
    margin-left: 35px;
    position: relative;
}

.link-container{
    position: relative;
    margin-right: 2px;
    max-width: 358px;
    width: 90%;
}

.new-session-container .session-link ion-text{
    width: 354px;
    margin-bottom: 10px;
    background-color: #F5F3F1;
    padding: 10px;
    padding-right: 35px;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 14px;
}

.alert-message{
    transition: ease all .5s;
}

.alert-if-disabled{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.session-link .button{
    font-size: 14px;
    height: 34px;
    padding: 0;
    margin-bottom: 0;
    margin-top: 8px;    
}

.session-link svg{
    position: absolute;
    top: 16px;
    right: 12px;
    transition: .3s ease all;
}

.session-link svg:active{
    transform: scale(0.9)
}

.sc-ion-modal-ios-h{
    --height: 216px;
}

.modal .button-primary{
    margin-right: 20px;
}

.btn-footer-session{
    display: flex;
    position: absolute;
    bottom: 16px;
    left: 30px;
}

.btn-footer-session .button{
    font-size: 14px;
    padding: 0;
    height: 36px;
    margin-right: 5px;
}

@media (max-height: 600px){
   
    .new-session-container-mobile{
        height: 700px;
    }
}


@media (max-width: 869px){

    .session-link{
        display: block;
    }

    .new-session-form {
        height: 92vh;
    }
}

@media (max-width: 768px) {

    .new-session-container {
        position: absolute;
        top: 104px;
        left: 8px;
        width: 97%;
        height: calc(100% - 140px);
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        overflow: scroll;
        overflow-x: hidden;
    }

    .new-session-form{
        height: 654px;
    }

    .session-form-codes-row{
        display: block;
    }

    .new-session-container .new-session-form ion-text {
        width: 92.5%;
    }

    .new-session-container .form-group {
        width: 92.5%;
        margin-top: 10px;
    }

    .new-session-container .form-group.name {
        width: 92.5%;
    }

    .tools-container ion-col{
        margin-bottom: 20px;
    }

    .session-link {
        display: block;
    }

    .session-link .button {
        width: 334px;
        margin-left: 5px;
    }

    .session-link svg {
        top: 27px;
        right: 41px;
    }

    .new-session-container-mobile{
        height: 820px;
        margin-bottom: 30px;
    }

}

@media (max-width: 414px){

    .new-session-container {
        height: calc(100% - 140px);    
    }

    .new-session-container .new-session-form ion-text {
        width: 92.5%;
        font-size: 13px;
    }

    .link-container {
        max-width: 428px;
        width: 100%;
    }

    .session-link svg {
        right: 35px;
        width: 24px;
        height: 32px;
        top: 10px;
    }

    .session-link .button {
        width: 290px;
        margin-left: 0px;
    }

    .new-session-form{
        height: 586px;
    }

    .lineBreak{
        display: none;
    }
}


@media (max-height: 414px){

    .new-session-container {
        overflow: scroll;
    }

    .session-link {
        display: block;
    }

    .new-session-container .session-link ion-text {
        width: 84%;
        margin-bottom: 10px;
    }

}








