h1, h2, h3, h4, p{
    color: var(--corporate-black);
}

a{
    text-decoration: none;
}

p{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
}

#background-content{
    background-color: none;
}

ion-button{
    font-family: 'Open Sans'
}

ion-text{
    color: var(--corporate-black)
}

ion-input{
    color: var(--corporate-black) !important;
    border: 1px solid #C4C4C4;
    border-radius: 9px;
    margin-top: 10px;
}

.sc-ion-input-ios-h {
    --padding-top: 8px;
}

input{
    color: var(--corporate-black) !important;
}

ion-button{
    font-size: 14px;
}
  
.header-md::after{
content: none;
}

.footer-md::before{
content: none;
}

h1, h2, h3, h4, h5{
    color: #363636;
    margin: 0;
    font-family: 'Ubuntu', 'Open Sans';
    font-weight: 500;
}

h1{
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
}

h3{
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 18px;
}

/*INPUTS*/

.native-input.sc-ion-input-ios{
    padding-left: 10px;
}

input:focus-visible {
    outline: 1px solid #a39e9e;
}
