.feedback{
    background-image: url(../../../assets/img/background.png);
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 8vh;
    text-align: center;
    transition: 0.4s;
}

.feedback-form{
    position: relative;
    width: 484px;
    background-color: #FFFFFF;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 40px;
    margin: 0 auto;
}

.feedback-form input#email.form-control{
    margin-top: 14px;
}

.back-icons a{
    font-family: 'Open Sans';
    font-weight: 700;
    color: #FFFFFF;
}

.feedback input{
    width: 100%;
    padding: 10px;
    background-color: #F5F3F1;
    border: 1px solid rgba(54, 54, 54, 0.05);
    border-radius: 4px;
    margin-top: 15px;
    font-family: 'Open Sans';
}

.feedback textarea{
    width: 100% !important;
    padding: 10px;
    background-color: #F5F3F1;
    border: 1px solid rgba(54, 54, 54, 0.05);
    border-radius: 4px;
    margin-top: 15px !important;
    font-family: 'Open Sans';
}

.feedback-form .rating-star{
    margin-top: 10px;
}

.feedback-form .rating-star h1{
    font-size: 20px;
    margin-bottom: 10px;
}

.feedback-form .rating-star svg{
    cursor: pointer;
}

.feedback ion-button{
    margin-top: 20px;
    width: 100%;
}

@media (max-width: 498px){

    .feedback-form {
        width: 376px;
        padding: 26px;
    }

}

@media (max-width: 398px){
    .feedback-form {
        width: 320px;
        padding: 35px;
    }
}
