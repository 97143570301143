.account-plan-container{
    background-image: url(../../assets/img/background.png);
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: center;
    overflow-y: auto;
}
.promo{
    position: absolute;
    right: -15px;
    top: -29px;
    font-size: 11px;
    font-weight: 700;
    background-color: #79CE67;
    color: #FFFFFF;
    padding: 4px 8px;
    border-radius: 5px;
    z-index: 3;
    text-align: center;
    box-shadow: #33333355 0px 0px 5px;
}
.account-plan{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background-color: #FFFFFF;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 40px;
    padding-bottom: 20px;
    max-width: 400px;
    width: 90%;
    z-index: 9;
}
.account-plan.error{
    background: white;
    padding: 50px;
    position: absolute;
    margin-top: 20%;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.account-plan.error h3{
   color: #363636;
}

.ios-alert{
    font-size: 12px;
    margin: -7px 0 5px;
    color: #984FB2;
}
.legal-alert{
    font-size: 12px;
    margin: -7px 0 5px;
    color: #333333;
}

ion-card.card-active{
    background-color: #984FB2;
}

ion-card.card-active ion-card-title,
ion-card.card-active ion-card-subtitle,
ion-card.card-active ion-card-content{
    color: #FFFFFF;
}
ion-card ion-card-content{
    padding-bottom: 12px!important;
}
.account-plan-container .account-plan ion-card {
    margin-bottom: 12px;
}

.account-plan-center{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.account-plan ion-card {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    border: 1px solid rgba(54, 54, 54, 0.2);
    border-radius: 4px;
    box-shadow: none;
    overflow: visible;
}

.account-plan ion-card:last-of-type {
    margin-bottom: 15px;
}

.account-plan ion-card-header{
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 8px;
}

.account-plan ion-card-title{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
}

.account-plan ion-card-content{
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
}

.account-plan .button{
    width: 100%;
    text-align: center;
}

.account-plan p{
    text-align: left;
}

.account-plan p:last-of-type{
    margin: 0;
}

.account-plan .unsubscribe{
    display: flex;
    align-items: center;
}

.account-plan .unsubscribe p:last-of-type{
    margin-left: 4px;
    color: #79CE67;
    cursor: pointer;
}

.unsubscribe-btn{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.unsubscribe-btn .button-primary{
    margin: 10px 0 !important;
}

.unsubscribe-btn .button-tertiary{
    margin-bottom: 0;
}

@media (max-height: 720px) {

    .account-plan{
        margin: 7vh 0 48px;
    }

    .account-plan-container .footer-login{
        bottom: 10px;
    }

    .account-plan-container .footer-login svg{
        width: 106px;
    }

}

@media (max-width: 720px) {

    .account-plan .unsubscribe p{
       font-size: 12px;
    }
    

}