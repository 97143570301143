.box canvas{
    margin: 0;
    background-color: #FFF;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgb(0 0 0 / 3%);
    border-radius: 10px;
    position: relative;
    top: 162px;
    left: 179px;
}

#drawcanvas{
    position: absolute;
    width: 688px;
    height: 484px;
}

#johariscreen{
    position: absolute;
    width: 688px;
    height: 484px;
    z-index: 10;
    mix-blend-mode: darken;
}

#johariscreen img{
    max-width: 92%;
}

.canvas-form-text{
    position: absolute;
    top: 155px;
    left: 100px;
    background-color: white;
    padding: 10px;
    width: 201px;
    height: 139px;
    z-index: 99;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgb(0 0 0 / 3%);
    border-radius: 10px;
}

.canvas-form-text p{
    margin: 4px 0;
}