.waiting-room-container{
    background-image: url(../../assets/img/background.png);
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: center;
}

.waiting-room{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
    background-color: #FFFFFF;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 40px;
    width: 48%; 
    height: 46vh;
}

.waiting-room-center{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.waiting-room-container svg:first-of-type{
    margin-bottom: 20px;
}

.waiting-room-container svg:last-child{
    position: absolute;
    bottom: 25px;
    right: 42%;
}