.history-content{
    position: relative;
    width: 70%;
    height: 95vh;
    margin: 20px 16px 0px 0px!important;
    padding: 50px 44px;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    overflow: scroll;
}

/* .history-content ion-header h1{
    margin-top: 44px !important;
    margin-left: 32px !important;
} */

.history-icon{
    margin-left: 15px;
}

.history-content .sessions-table{
    margin-top: 115px;
    margin-bottom: 20px;
}

.history-content .sessions-table ion-grid{
    display: flex;
    flex-direction: column;
}

.history-content .session-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F5F3F1;
}

.history-content .session-row a{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F5F3F1;
}

.history-content .session-row svg{
    cursor: pointer;
}

.history-content .session-row p{
    font-weight: 400;
}

.history-content .session-row .date{
    margin-right: 30px;
    width: 80px;
}


.history-content .session-row .date p{
    font-weight: 600;
}


.history-content .session-row .hour{
    width: 50px;
    margin-right: 30px;
}

.history-content .session-row .code{
    width: 70px;
    margin-right: 30px;
}

.history-content .session-row .name{
    width: 124px;
    margin-right: 30px;
}


.history-content .desktop-history{
    display: flex;
}


@media (max-width: 768px) {

    .history-content {
        position: absolute;
        top: 104px;
        left: 8px;
        width: 97%;
        height: 86vh;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .history-content ion-header h1{
        margin-right: 25px;
    }

    .history-content .session-row{
        margin: 0;
        padding: 0;
    }

    .history-content .sessions-table {
        margin: 82px 0px 0px 8px;
    }

    .history-content .sessions-table {
        margin: 0;
        padding: 8px;
    }

    .history-content .sessions-table ion-grid{
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .history-content .session-row {
        width: 80vw;
        margin-top: 20px;
        display: flex;
    }

    .history-content .session-row svg{
        width: 24px;
    }
    
    .history-content .session-row .date p{
        font-weight: 600;
    }
    
    .history-content .session-row .hour{
        margin-right: 25px;
    }
    
    .history-content .session-row .code{
        margin-right: 14px;
    }
    
    .history-content .session-row .name{
        margin-right: 10px;
        width: 70px;
    }

}

@media (max-width: 414px){

    .session-row .code,
    .session-row .hour
    {
        margin:0;
    }

    .history-content .desktop-history{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}



