.toys{
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
}
.button-controls{
    position: absolute;
    bottom: 20px;
    width: 136px;
    height: 97px;
    background: #fff;
    border-radius: 8px;
    text-align: center;
    z-index: 100;
    right: 16px;
}
.btn-move, .btn-stair{
    position: absolute;
    display: block;
    background: #F5F3F1;
    width: 36px;
    height: 36px;
    border: 1px solid #F5F3F1;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 36px;
    text-align: center;
    bottom:10px;
}
.btn-move:active{
    background: #d4d4d3;
}
.btn-move.top{
    left: calc(50% - 18px);
    top:10px;
    bottom:auto;
}
.btn-move.left{
    left: calc(50% - 58px);
}

.btn-move.rotate.left, .btn-move.rotate.right{
    top:10px;
    bottom:auto;
    background: #ffffff;
}
.btn-move.bottom{
    left: calc(50% - 18px);
}
.btn-move.right{
    left: calc(50% + 22px);
}
.btn-stair{
    right: auto;
    left: -45px;
    background-color: #ffffff;
    border: 1px solid #F5F3F1;
    bottom: 0;;
}