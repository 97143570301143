.account-content{
    position: relative;
    width: 70%;
    height: 95vh;
    margin: 20px 16px 0px 0px!important;
    padding: 50px 44px;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    overflow: scroll;
}

.account-content h3{
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.account-content .profile,
.account-content .plan{
    margin-top: 50px;
    background-color: #FBFBFB;
    padding: 20px 30px;
    padding-right: 24px;
    padding-bottom: 30px;
    border-radius: 6px;
    transition: .4s ease all;
}

.account-content .profile.false{
    height: 224px;
}

.account-content .profile.true{
    height: 272px;
}

.account-content .plan{
    margin-top: 20px;
}

.account-content .plan .btn .button-secondary{
    margin-right: 10px;
}

.account-content .profile .profile-header,
.account-content .plan .plan-header{
    margin-bottom: 18px;
    border-bottom: 1px solid #EFEFEF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-content .profile .form-group{
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 16px;
}

.account-content .profile .form-group .input-group{
    margin-bottom: 12px;
}

.account-content .profile .form-group p{
   margin: 0;
   font-size: 14px;
   font-weight: 600;
}

p.error-message {
    margin-left: 8px !important;
    color: red;
 }

.account-content .profile .form-group h4{
    width: 160px;
   font-size: 14px;
   color: #9B9B9B;
   font-family: 'Open Sans';
}

.account-content .profile label{
    font-family: 'Open Sans';
    font-size: 14px;
    text-align: left ;
    font-weight: 600 ;
    margin: 0;
}

.account-content .profile label{
    font-family: 'Open Sans';
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    margin: 0;
}

.account-content .profile input{
    width: 30%;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    transition: .3s ease all;
}

.account-content .profile.true input{
    border: 1px solid rgba(54, 54, 54, 0.05);
    background-color: #F5F3F1;
    border-radius: 4px;
    padding: 4px;
}

.account-content .plan .plan-content-info{
    display: flex;
    margin-bottom: 16px;
}

.account-content .plan .plan-content-info h4{
    width: 160px;
    font-size: 14px;
    color: #9B9B9B;
    font-family: 'Open Sans';
}

.account-content .plan .plan-content-info p{
    margin: 0;
    font-weight: 600;
}

.account-content .plan .plan-content-info.first p{
    font-weight: 700;
}

.account-content .free-plan{
    margin-top: 20px;
}

.acount-content ion-card.card-active{
    background-color: #984FB2;
}

.acount-content ion-card.card-active ion-card-title,
.acount-content ion-card.card-active ion-card-subtitle,
.acount-content ion-card.card-active ion-card-content{
    color: #FFFFFF;
}

.account-content ion-card {
    width: 34%;
    margin-bottom: 15px;
    margin-top: 5px;
    border: 1px solid rgba(54, 54, 54, 0.2);
    border-radius: 4px;
    box-shadow: none;
}

.account-content ion-card-header{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.account-content ion-card-title{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
}

.account-content ion-card-content{
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
}

.account-content .change-plan{
    display: flex;
    align-items: flex-end;
}


.account-content .version-code {
    bottom: -40px;
}

@media (max-width: 938px){
    .account-content .profile .profile-header, 
    .account-content .plan .plan-header{
        display: block;
    }

    .account-content .profile.false {
        height: 220px;
    }

    .account-content .profile.true {
        height: 260px;
    }
}

@media (max-width: 768px){

    .account-content{
        position: absolute;
        top: 104px;
        left: 8px;
        width: 97%;
        height: 86vh;
        margin-top: 20px !important;
        padding: 50px 23px;
    }
    .account-content .change-plan{
        display: block;
    }

    .account-content .profile,
    .account-content .plan{
        position: relative;
    }

    .account-content .profile ion-button,
    .account-content .plan .btn{
        position: absolute;
        bottom: 0px;
        left: 26px;
    }

    .account-content .profile .form-group,
    .account-content .plan .plan-content-info {
        display: block;
    }

    .account-content .profile .form-group h4,
    .account-content .plan .plan-content-info h4 {
       margin-bottom: 5px;
    }

    .account-content .profile.false {
        height: 326px;
    }

    .account-content .profile.true {
        height: 400px;
    }

    .account-content .profile input{
        width: 100%;
    }

    .account-content .profile, .account-content .plan {
        padding-bottom: 64px;
    }
}

@media (max-width: 380px){
    .account-content .profile, .account-content .plan {
        padding-bottom: 109px;
    }
}
