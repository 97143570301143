.left-bar-container{
    position: absolute;
    width: 60px;
    height: auto;
    background-color: #FFFFFF;
    top: 50%;
    margin-top: -150px;
    left: 20px;
    border: none;
    box-shadow: 0px 17px 42px rgb(0 0 0 / 5%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 20;
}

.left-bar-container.dice{
    margin-top: 0;
}

.left-bar-container.toy{
    margin-top: -180px;
}

.left-bar-container.sandbox{
    margin-top: -172px;
}

.left-bar-container.teddy{
    margin-top: -150px;
}

.left-bar-container-none{
    display: none;
}

.left-menu-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.left-menu-container svg:first-of-type{
    margin-top: 15px;
    margin-bottom: 20px;
    /* border-bottom: 1px solid #F5F3F1; */
}

.left-menu-container svg{
    margin-bottom: 5px !important;
    cursor: pointer;
}

.left-bar-container.draw .left-menu-container .johari-icon{
    margin-top: 0;
    border-top: 2px solid #F5F3F1;
    padding-top: 10px;
}

.left-menu-container svg:last-of-type{
    margin-top: 10px;
    margin-bottom: 14px !important;
}

.left-bar-container .roll-dices{
    padding: 10px 0 5px 0;
}

.left-bar-container .roll-dices svg{
    cursor: pointer;
}

.left-bar-container .roll-dices:active{
    transform: scale(0.8);
}

@media (max-width: 812px) {

    .left-bar-container{
       top: 388px;
    }
    
}

@media (max-width: 498px) {

    .left-bar-container{
        display: none;
    }
}

@media (max-height: 578px){
    .left-bar-container{      
        width: auto;
        top: 100px;
        margin-top: 0;
    }

    .left-bar-container.toy,
    .left-bar-container.sandbox,
    .left-bar-container.teddy{
        margin-top: 0;
    }

    .left-menu-container svg{
        width: 30px;
        margin-left: 10px;
        margin-top: 5px;
    }

    .left-menu-container svg:first-of-type{
        margin-top: 5px;
    }

    .left-menu-container svg:last-of-type{
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px !important;
    }

    .left-menu-container{
        flex-direction: row;
        align-items: center;
    }

    .left-bar-container.dice {
        top: 100px;
    }

    .left-bar-container .roll-dices{
        padding: 2px 5px;
    }
}

