.teddy-text{
    display: none;
}

.teddy-text.expanded{
    display: flex;
    align-items: center;
    overflow: scroll;
    position: absolute;
    padding: 10px;
    left: 70px;
    height: 55px;
    background-color: #fff;
    border: 1px solid #F5F3F1;
    border-radius: 12px;
}

.teddy-text.expanded.say{
    top: 185px;
}

.teddy-text.expanded.think{
    top: 238px;
}

.teddy-text.expanded input{
   margin-top: 0;
   margin-right: 10px;
}

.teddy-select-constructor{
    position: absolute;
    background-color: #fff;
    border: 1px solid #F5F3F1;
    border-radius: 12px;
    padding: 10px 14px;
    left: 70px;
}

.teddy-select-constructor.face{
    top: 50px;
 }

 .teddy-select-constructor.body{
    top: 98px;
 }

.teddy-select-constructor.paws{
    top: 141px;
 }


.teddy-select-parts{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left-menu-container .teddy-select-parts svg:first-of-type{
    margin: 0 10px 0 0!important;
}

.left-menu-container .teddy-select-parts svg:last-of-type{
    margin: 0 !important;
}

.teddy-select-parts .individual-parts-selector{
    margin: 0 10px;
    height: 55px;
    width: 160px;
    background-color: #F5F3F1;
    border-radius: 10px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
}

@media (max-height: 578px){

    .teddy-select-constructor.ears{
        top: 50px;
        left: 0px;
    }

    .teddy-select-constructor.face{
        top: 50px;
        left: 34px;
    }

    .teddy-select-constructor.body{
        top: 50px;
        left: 76px;
    }

    .teddy-select-constructor.paws{
        top: 50px;
        left: 115px;
    }

    .teddy-text.expanded.say{
        top: 50px;
        left: 130px;
    }

    .teddy-text.expanded.think{
        top: 50px;
        left: 150px;
    }

}