.sandbox-menu{
    transition: .4s ease all;
}

.sandbox-menu.collapsed{
    display: none;
}

.sandbox-menu.expanded .components.true{
    margin-top: -68px;
}

.sandbox-menu.expanded{
    overflow: scroll;
    position: absolute;
    padding: 10px;
    top: 0px;
    left: 65px;
    width: 120px;
    height: 60vh;
    background-color: #fff;
    border: 1px solid #F5F3F1;
    border-radius: 12px;
    scrollbar-width: none;
}

.sandbox-menu.expanded::-webkit-scrollbar{
    display: none;
}

.container-sandbox{
    position: relative;
}

.top-arrow{
    position: sticky;
    top: 0px;
    left: 30px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    opacity: .8;
    border-radius: 12px 0 0 12px;
    background-color: #ffffff;
    color: #4d4c4c;
    line-height: 47px;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans';
    transform: rotate(270deg);
    z-index: 2;
}

.bottom-arrow{
    position: sticky;
    top: 55vh;
    left: 33px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    opacity: .8;
    border-radius: 12px 0 0 12px;    
    background-color: #ffffff;
    color: #4d4c4c;
    line-height: 47px;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans';
    transform: rotate(90deg);
    z-index: 2;
}

.sandbox-menu.expanded img{
    width: 60%;
    margin: 20px;
}

.sandbox-menu.expanded.height-auto{
    height: auto;
}

.sandbox-menu .selected-image{
    transition: .4s ease all;

}

.sandbox-menu .selected-image.active{
    background-color: rgba(121, 206, 103, 0.2);
    border-radius: 12px;
    border: none;
}

.sandbox-menu .selected-image.inactive{
    background-color: none;
}

@media (max-height: 578px){

    .bottom-arrow, .top-arrow{
        display: none;
    }

    .sandbox-menu.expanded{
        overflow: scroll;
        position: absolute;
        padding: 10px;
        top: 50px;
        left: 0px;
        width: 300px;
        height: 130px;
        background-color: #fff;
        border: 1px solid #F5F3F1;
        border-radius: 12px;
    }

    .sandbox-menu.expanded .components .images{
        display: flex;
        align-items: center;
        overflow: scroll;
    }

    .sandbox-menu.expanded .components.true .images{
        margin-top: 56px;
        height: 160px;
    }

    .sandbox-menu.expanded img {
        width: 100%;
    }

    .sandbox-menu .selected-image{
        margin: 0px 20px;
    }

}