.dices{
    margin: 0;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 300px);
    height: 500px;
    width: 600px;
}
canvas{
    margin: 0 auto;
    width: 500px;
    height: 600px;
    border-radius: 12px;
}