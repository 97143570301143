.sidebar-menu{
    position: relative;
    background-color: #FFFFFF;
    width: 25%;
    height: 95vh;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    margin: 20px 22px 0 20px!important;
    border-radius: 10px;
    border: 1px solid #F5F3F1;
}

.sidebar-menu-none{
    display:none;
}

.logo{
    margin: 30px 20px 20px 20px;
    cursor: pointer;
}

.sidemenu-icons-container{
    margin-top: 30px;
}

.sidemenu-icons{
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 16px;
    border-bottom: 1px solid #F5F5F5;
    transition: .3s ease all;
    padding-bottom: 5px;
}

.sidemenu-icons:first-of-type{
    margin-bottom: 10px;
}

.sidemenu-icons:active{
    transform: scale(0.9)
}

.sidemenu-icons:hover{
    cursor: pointer;
}

.sidemenu-icons.valoration{
    padding: 0;
    width: 21vw;
    position: absolute;
    bottom: 86px;
    left: -6px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #F5F3F1;
    border-radius: 10px;
    z-index: 99;
}

.sidemenu-icons.valoration svg{
    margin-left: 15px;
}

.sidemenu-icons.valoration p{
    margin-top: 15px;
    margin-left: 8px;
}

.session-menu,
.tools-menu,
.logout-menu{
    margin-right: 12px;
}

.bottom-sidebar{
    position: absolute;
    bottom: 10px;
    width: 90%;
}

.sidebar-menu ion-card{
    /*border: 1px solid rgba(54, 54, 54, 0.2);*/
    font-family: 'Open Sans';
    border-radius: 10px;
    box-shadow: none;
    background-color: #984FB2;
    margin-bottom: 76px;
}

.sidebar-menu ion-card ion-card-content{
    padding-bottom: 0 !important;
}

.sidebar-menu ion-card ion-card-header{
    padding-bottom: 0;
}

.sidebar-menu ion-card ion-card-title{
    font-size: 22px;
    font-weight: 700;
}

.sidebar-menu ion-card ion-card-title p{
    color: #FFFFFF;
}

.sidebar-menu ion-card ion-card-content{
    font-size: 10px;
    font-weight: 400;
    margin: 8px 0;
}

.sidebar-menu ion-card ion-card-title,
.sidebar-menu ion-card ion-card-content,
.sidebar-menu ion-cardion-card-content{
    color: #FFFFFF;
}

.sidebar-menu ion-card ion-button{
    border: 1px solid #FFFFFF;
    margin-left: 14px;
    width: 124px;
}

.logout{
    border-top: 1px solid #F5F5F5;
}

.logout ion-button{
    margin-top: 20px;
    padding: 0;
}

.menu-sidebar-mobile{
    display: none;
}

@media (max-width: 768px) {
    
    .sidebar-menu {
        position: relative;
        height: 112px;
        width: 100%;
        background-color: #FFFFFF;
        margin: 5px 6px !important;
    }
    
    .menu-sidebar-mobile{
        display: block;
        position: absolute;
        top: 40px;
        right: 36px;
    }
    
    .logout{
        display: none;
    }
    
    .menu-mobile-list{
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        padding: 20px;
        z-index: 10;
        position: absolute;
        top: 37px;
        width: 300px;
        text-align: end;
        right: 0px;
        z-index: 12;
        border-radius: 10px;
        border: 1px solid #F5F3F1;
        box-shadow: 0px 17px 42px rgb(0 0 0 / 3%);
    }
    
    .menu-mobile-list button, 
    .menu-mobile-list a{
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 600;
        padding: 14px 0;
        color: #363636;
        border-bottom: 1px solid #F5F3F1;
        background: none;
        text-align: end;
    }
    
    .menu-mobile-list a:last-of-type{
        border-bottom: none;
    }

    .sidemenu-icons.valoration{
        display: none;
    }

    .bottom-sidebar {
        display: none;
    }
    
}


@media (max-height: 414px) {

    .sidemenu-icons-container {
        display: none;
    }

    .sidemenu-icons{
        border-bottom: none;
        margin-bottom: 20px !important;
    }

    .sidemenu-icons p{
        font-size: 12px;
    }

    .logout{
        border-top: none;
    }

    .logout ion-button {
        margin-top: 15px;
        margin-left: 8px;
        padding: 0;
    }

    .bottom-sidebar{
        position: relative;
        bottom: 0;
        width: 0;
    }
}

@media (max-height: 620px) {
    .hide-if-short{
        display: none;
    }
}