.tools-menu-container{
    display: inline-flex;
    margin-left: 20px;
    transition: .3s ease all;
    height: 60px;
}

.tools-menu-icon-expanded{
    background: #FFFF;
    border-radius: 12px 0px 0px 12px;
    border-right: 1px solid #F5F5F5;
}

.tools-menu-icon-collapsed{
    background: #FFFF;
    border-radius: 12px;
}

.tools-menu-container.expanded{
    background-color: #FFFFFF;
    border-radius: 0px 12px 12px 0;
    margin: 0;
    width: 100%;
;
}

.tools-menu-container.collapsed{
    width: 0%;
}

.tools-menu-container.expanded svg:first-of-type{
    margin-left: 12px;
}

.tools-menu-icon-expanded{
    cursor: pointer;
}

.tools-menu-container.expanded svg{
    cursor: pointer;
    margin-top: 6px;
    margin-right: 6px;
    width: 44px;
}

.tools-menu-container.expanded svg:last-of-type{
    margin-right: 12px;
}

.tools-menu-container-none{
    display: none;
}

@media (max-width: 812px) {

    .tools-menu-container {
        margin-left: 21px;
    }
    
}

@media (max-width: 498px) {

    .tools-menu-container{
        display: none;
    }

    .tools-menu-icon-expanded svg{
        width: 48px;
    }

    .tools-menu-container.expanded svg{
        width: 10px;
    }
}

@media (max-height: 578px){
    .tools-menu-container{      
       height: 58px;
    }

    .tools-menu-icon-expanded svg,
    .tools-menu-icon-collapsed svg{
        width: 38px;
        margin-left: 7px;
    }
    
    .tools-menu-icon-collapsed{
        width: 52px;
    }

    .tools-menu-container.expanded svg{
        width: 38px;
    }
        
}

