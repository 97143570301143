.signup input{
    width: 100%;
    height: 36px;
    padding: 10px;
    padding-top: 7px;
    background-color: #F5F3F1;
    border: 1px solid rgba(54, 54, 54, 0.05);
    border-radius: 4px;
    margin-top: 14px;
    font-family: 'Open Sans';
}

.signup h2{
    font-family: 'Open Sans';
    font-weight: 700;
    margin: 20px 0;
}

.signup-form{
    margin-top: 10px;
}

.signup .form-group{
    position: relative;
}

.signup .button-primary{
    width: 100%;
    text-align: center;
}

.signup .privacy-policy-checkbox input{
    width: 30px;
    margin-top: 0;
    margin-right: 10px;
}

.signup .privacy-policy-checkbox a{
    color: #79CE67 !important;
    display: inline;
}

.signup .error-input{
    border: 1px solid #DF0000;
}

.signup .error-message{
    color: #DF0000;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
}

.signup .privacy-policy {
    font-size: 12px;
    margin-right: 10px;
    margin-left: 10px;
}

.signup .privacy-policy a,
.signup .existing-account{
    color: #79CE67;
    font-weight: 700;
    margin-left: -6px;
}

.existing-account-container p{
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 !important;
}

.existing-account-container a{
    cursor: pointer;
    margin-left: 6px;
}

.signup .signup-form .form-group svg{
    position: absolute;
    right: 3%;
    left: auto;
    bottom: 8px;
}
