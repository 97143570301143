.privacy-container{
    background-image: url(../../assets/img/background.png);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: center;
    padding-top: 50px;
}

.privacy-policy{
    width: 50vw;
    margin: 0 auto;
    position: relative;
    padding: 50px;
    height: 85vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    overflow: hidden;
}

.privacy-policy h2{
    font-family: 24px;
    text-transform: uppercase;
}

.privacy-policy h3{
    font-family: 16px !important;
}

.privacy-policy svg{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.privacy-policy .scroll-buttons .top-arrow{
    position: absolute;
    left: calc(25vw - 50px);
    top: 1%;
    transform: rotate(90deg);
    z-index: 2;
}

.privacy-policy .scroll-buttons .bottom-arrow{
    position: absolute;
    top: calc(90vh - 100px);
    left: calc(25vw - 50px);
    transform: rotate(270deg);
    z-index: 2;
}

.privacy-policy .privacy-policy-text{
    overflow: scroll;
    height: 100%;
}

.privacy-policy td{
    border: 1px solid black;
    padding: 6px;
}
table{
    color:#333333;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
}
ul{
    color:#333333;
    font-family: 'Open Sans';
    font-size: 14px;
}

@media (max-height: 578px){

    .privacy-policy .scroll-buttons .bottom-arrow, 
    .privacy-policy .scroll-buttons .top-arrow{
        display: block;
    }
}

@media (max-width: 498px) {

    .privacy-container {
        padding-top: 40px;
    }

    .privacy-policy{
        width: 310px;
        height: 91vh;
        padding: 39px
    }

    .privacy-policy p{
        font-weight: 400;
        font-size: 12px;
    }
}

