ion-button{
    letter-spacing: 0px;
}

input{
    font-size: 14px;
}

.login{
    background-image: url(../../assets/img/background.png);
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 11vh;
    text-align: center;
    transition: 0.4s;
}

.back{
    display: none;
}

.back-icons{
    position: absolute;
    top: -34px;
    left: 0;
    display: flex;
    align-items: center;
}

.back.visible{
    margin-left: 300px;
    display: block;
}

.login .back.visible a{
    font-family: 'Open Sans';
    font-weight: 700;
    color: #FFFFFF;
}

.login .session-form h1{
    font-family: 'Open Sans';
    font-weight: 700;
    margin-bottom: 30px;
}

.login .sc-ion-input-ios-h{
    background-color: #F5F3F1;
    color: #363636;
}

.login .privacy-policy-checkbox{
    margin-top: 6px;
    margin-bottom: 8px;
    display: flex;
}

.login .privacy-policy-checkbox input{
    margin-top: 3px;
    margin-right: 5px;
}

.login .privacy-policy-checkbox input:not(:checked) {
    background: none;
    appearance: none;
    border: 1px solid rgba(54, 54, 54, 0.2);;
    width: 13px;
    height: 14px;
    border-radius: 3px;
    padding: 0;
    margin-top: 2px;
}

.login .privacy-policy-checkbox input:checked {
    width: 13px;
    height: 14px;
    border-radius: 3px;
    padding: 0;
    margin-top: 2px;
}

.login .privacy-policy-checkbox p, 
.login .privacy-policy-checkbox a{
    text-align: initial;
    font-size:12px;
    margin-bottom: 0;
}

.login .privacy-policy-checkbox a{
    color: #79CE67 !important;
    display: inline;
}

.login .error-input{
    border: 1px solid #DF0000;
}

.login .error-message{
    color: #DF0000;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
}

.login .button.therapist-access{
    margin-bottom: 0;;
}

.logo-eholo{
    width: 60%;
}

.session-form{
    position: relative;
    width: 400px;
    background-color: #FFFFFF;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 46px 40px;
    margin: 0 auto;
}

.session-form p:first-of-type{
    margin-bottom: 5px;
}

.separation{
    position: relative;
    overflow: hidden;
}

.login .session-form .lineBreak{
    display: inline-block;
    background-color: #FFFFFF;
    z-index: 1;
    width: 31px;
    height: 12px;
    margin: 15px 0 30px 0;
}

.login .session-form .line{
    background-color: #F5F5F5;
    position: absolute;
    top:25px;
    width: 100vw;
    height: 1px;
}

.login a{
    font-size: 14px;
    display: block;
}

.login p{
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #363636;
}

.login .button-primary{
    width: 100%;
    height: 36px;
    margin-top: 14px;
    margin-bottom: 0;
}

.login .button-tertiary.back{
    margin: 0px;
}

.login .session-form .signin .button-tertiary.back-password{
    margin-top: 18px;
}

.login #first-connection{
    margin-top: 100px;
}

.login ion-list{
    margin-top: 60px;
}

.create-account.container{
    justify-content: center;
    display: flex;
    align-items: center;
}

.create-account{
    display:flex;
    justify-content: center;
}

.link-signin{
    font-family: 'Open Sans';
    margin-left: 4px;
    color: #79CE67;
    font-weight: 700;
}

.create-account-container ion-button{
    width: 100px;
    margin: 0;
    margin-top: 1px;
    margin-left: -6px;
}

.create-account-container p{
    margin-top: 8px;
}

.create-account-container.access p{
    font-size: 13px;
}

.existing-account-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.existing-account-container p,
.existing-account-container ion-button{
    margin: 0;
    padding: 0;
}

.footer-login{
    display: flex;
    position: absolute;
    bottom: 18px;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    z-index: 0;
}

@media (max-width: 768px) {
   
    .session-form{
        width: 404px;
        padding: 22px;
    }

    .login .privacy-policy-checkbox {
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    .login .button{
        font-size: 16px;
        padding: 0;
    }

    .login .therapist-access{
        margin-top: 20px;
        padding: 6px;
        font-size: 15px;
    }

    .login .privacy-policy-checkbox input {
        margin-top: -2px;
        height: 18px;
        margin-right: 10px;
    }

    /* .login .privacy-policy-checkbox p{
        width: 200px;
    } */

    .login .separation{
        display: none;
    }

    .link-signin{
        font-family: 'Open Sans';
        margin-left: 5px;
    }
}

@media (max-width: 414px) {

    .login p{
        font-size: 13px;
    }
   
    .session-form {
        width: 343px;
        padding: 16px;
    }

}

@media (max-height: 595px){

    .login{
        padding-top: 4vh;
    }

    .footer-login {
        bottom: 0;
    }
    
    .footer-login svg{
        width: 100px;
    }
}

@media (max-height: 525px){
    
    
    .footer-login {
        display: none;
    }
}

@media (max-height: 414px){
    
    .login{
        overflow: scroll;
    }

    .session-form ion-button{
        margin-top: 10px !important;
        margin-bottom: 8px !important;
    }

    .separation{
        display: none;
    }
}