#sandbox-pannel{
    width: 688px;
    height: 484px;
    margin: 0;
    display: flex;
    background-color: #FFF;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgb(0 0 0 / 3%);
    border-radius: 10px;
    position: relative;
    top: 162px;
    left: 179px;
    z-index: 98;
    background-image: url(../../../../../assets/img/sand.jpg);
    background-size: cover;
    background-position: center;
}

#sandbox-pannel .sandbox-image{
    position: absolute;
    width: 12%;
    transition: width 0.2s ease-in-out;
    height: 20%;
}
#sandbox-pannel .sandbox-image.large{
    width: 22%;
    height: 45%;
}
.box{
    width: 100vw;
    height: 100vh;
    transform-origin: left top;
    transition: 0.1s;
}
.remove-element{
    color: #FFFFFF;
    background-color: rgb(121, 206, 103);
    position: absolute;
    top:0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    display: none;
    font-family: Robot, Arial, sans-serif;
    font-size: 10px;
    font-weight: bold;
}
.sandbox-image:hover .remove-element{
    display: block;
}
.sandbox-image img{
    max-height: 100%;
    transition: width 0.2s ease-in-out;
}

@media (max-height: 578px){

    #sandbox-pannel{
       /* width: 500px;
        height: 200px;*/
    }
}