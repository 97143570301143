.left-menu-draw{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.left-menu-draw svg:first-of-type{
    margin-top: 15px;
    margin-bottom: 20px;
    /* border-bottom: 1px solid #F5F3F1; */
}

.left-menu-draw svg{
    margin-bottom: 5px !important;
}

.left-menu-draw svg:last-of-type{
    margin-top: 10px;
}