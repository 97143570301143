.next-session{
    position: absolute;
    bottom: 132px;
    width: 100%;
    padding: 0 5px;
    margin: 68px 0 0 0;
    height: 25%;
    overflow: scroll;
}

.next-session-form-codes{
    display: flex;
    margin-top: 10px;
    flex-direction: column;
}

.next-sessions-form-codes input{
    width: 100%;
    margin: 10px 0;
}

.next-session-tools-container{
    border: none;
    margin-top: 10px;
    height: auto;
}

.next-session-tools-container ion-col{
    margin: 0;
    padding: 0;
}

.next-session-tools-container svg{
   margin-right: 10px;
   margin-top: 10px !important;
}
.next-sessions-form-codes p{
    margin: 0;
}

.new-tools-container ion-text{
    font-size: 12px;
}

.next-session-btn{
    position: absolute;
    bottom: 54px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.next-session-btn ion-button{
    position: relative !important;
    left: 0 !important;
    bottom: 0 !important;
}
