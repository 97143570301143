.color-collapsed{
    display: none;
}

.color-expanded{
    position: absolute;
    bottom: 16px;
    left: 58px;
    width: 192px;
    display: flex;
    background-color: #fff;
    border: 1px solid #F5F3F1;
    border-radius: 12px;
}

.left-menu-container .color-expanded svg{
    margin-top: 0 !important;
    margin-bottom: 5px !important;
}

.left-menu-container .color-expanded svg:last-of-type{
    margin-bottom: 5px !important;
}

@media (max-height: 578px){

    .color-expanded {   
        position: absolute;
        top: -1px;
        left: 208px;
        width: 135px;
        display: flex;
        background-color: #fff;
        border: 1px solid #F5F3F1;
        flex-direction: row;
        width: 222px;
        height: 92px;
        border-radius: 0px 12px 12px 12px;
        border-left: 0px;

    }
}