
.signin{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signin h2{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    margin: 20px 0;
}

ion-input{
    --background: #F5F3F1!important;
    font-family: 'Open Sans'!important;
    font-size: 14px!important;
    border-radius: 4px;
    --padding-top:8px!important;
    --padding-bottom:8px!important;
    border: 1px solid rgba(54, 54, 54, 0.05);
}

.signin input{
    width: 100%;
    height: 36px;
    padding: 10px;
    background-color: #F5F3F1;
    border: 1px solid rgba(54, 54, 54, 0.05);
    border-radius: 4px;
    margin-top: 14px;
    font-family: 'Open Sans';
}

.signin-form{
    margin-top: 0px;
    width: 100%;
}

input#email.form-control{
    margin-top: 0;
}

.signin .button-primary{
    width: 99.5%;
    margin-top: 20px;
    /* margin-bottom: 20px; */
    text-align: center;
}

.signin .forget-password{
    font-family: 'Open Sans';
    margin-top: 0px;
    font-weight: 600;
    font-size: 13px;
    color: #79CE67;
}

.signin .create-account-container{
    align-items: center;
}

.signin .create-account-container p{
   margin: 0 !important;
}

.signin-container svg{
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0);
}

.create-account-container{
    justify-content: center;
    display: flex;
    align-items: baseline;
}