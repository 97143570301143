.modal{
  width: 354px;
  position: absolute;
  top: calc(50% - 177px);
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  text-align: center;
  background-color: #E3F4E0;
  border: 1px solid rgba(121, 206, 103, 0.15);
  box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: .1s;
  animation-name: slidein;
}

.modal.delete{
  background-color: #FFFFFF;
  width: 500px;
  padding: 40px;
  border-radius: 10px;
}

.new-session-container .modal{
  top: calc(32% - 49px);
}

.modal h1{
  margin: 0 !important;
  margin-top: 10px !important;
}

.modal h3{
  margin: 20px 12px 15px 12px !important;
}

.modal p{
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  font-weight: 400;
}

.modal.warning{
    background-color: #FEECEC;
}

.modal.none{
    display: none;
}

.modal.unsubscribe{
  background-color: #FFFFFF;
  width: 400px;
  padding: 30px 0;
  border-radius: 10px;
}

.modal.unsubscribe p{
  margin-top: 20px !important;
  width: 325px;
}

.modal.unsubscribe .unsubscribe-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.modal.unsubscribe .unsubscribe-btn button{
  width: 100%;
  margin: 10px;
}

.modal.unsubscribe .unsubscribe-btn .button-tertiary{
  background-color: #FFFFFF;
}

@keyframes slidein {
    from {
      margin-top: 100%;
    }
  
    to {
      margin-bottom: 0%;
    }
  }


@media (max-width: 770px){
  .modal{
    left: 50vw;
  }

  .modal.delete{
    width: 400px;
    padding: 20px;
  }
}


@media (max-width: 414px) {
    .modal{
      width: 277px;
      top: calc(50% - 138px);
      left: 50vw;
      margin: 0!important;
      padding: 20px;
      position: fixed;
      box-sizing: border-box;
    }
    .dashboard-content h1.modal{
      margin: 0!important;
    }

    .modal.delete{
      width: 277px;
      padding: 20px;
    }

    .modal.delete .button-primary{
      margin-right: 0;
      margin-bottom: 15px;
    }
  }