.draw-box {
    height: 300vh;
    position: relative;
    overflow: auto;
    padding: 0;
    z-index: 1;
}

.draggable-box{
    position: absolute;
    top: 0;
    left: 93px;
    height: 1536px;
    width: 2048px;
    padding: 10px;
    background-image: url(../../../assets/img/dot.png);
    background-size: 20px;
    background-repeat: repeat;
}
.ball-cursor{
    position: absolute;
    left:-100px;
    top:-100px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    /*background-color: rgb(121, 206, 103);*/
    transition: 0.3s;
    z-index: 1000;
}

.draggable-box-none{
    display: none;
}

::-webkit-scrollbar {
    display: none;
}

.zoom-tools{
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 10000000;
}

.zoom-tools .move-icon{
    position: absolute;
    bottom: 25px;
    right: 0;
}

.zoom-tools svg{
    margin-bottom: 5px;
}

.zoom-tools.none{
    display: none;
}

#react-jitsi-container{
    width: 250px!important;
    height: 150px!important;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    border-radius: 12px;
    overflow: hidden;
}
.videocall-active{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.videocall-active #react-jitsi-container{
    width: calc(100vw - 100px) !important;
    height: calc(100vh - 200px) !important;
    top: 100px;
    right: auto;
    left:50px;
}
.final-notice{
    margin: 100px;
}
.final-notice *{
    text-align: center;
}

#react-jitsi-container.loaded{
    width: 300px!important;
    height: 210px!important;
}
#react-jitsi-frame{
    display: block!important;
}
.ion-page{
    display: block;
}
ion-footer.session-panel-footer{
    display: block;
    position: absolute;
    bottom:80px;
    /* z-index: 10000; */
    /*max-width: 410px;*/
    height: 0;
}

ion-header.session-header{ 
    display: block;
    position: absolute;
    top:0;
    z-index: 10000;
    left: 0;
    width: 35%;
    max-width: 380px;
}

.advert-mobile-screen{
    display: none;
}

.version-code-session{
    margin-right: 20px;
    position: absolute;
    bottom: -10px;
    right: 0;
}

.version-code-session p{
    font-size: 12px;
    color: #E5E5E5;
}


@media (max-width: 498px) {

    .advert-mobile-screen{
        display: block;
        width: 50%;
        position: absolute;
        top: 250px;
        left: 100px;
        text-align: center;
        margin-top: 33px;
        background-color: #FFFFFF;
        border: 1px solid rgba(121, 206, 103, 0.15);
        box-shadow: 0px 17px 42px rgb(0 0 0 / 5%);
        border-radius: 4px;
        padding: 20px;
    }

    canvas{
        display: none;
    }

    .draw-box .toys{
        display: none;
    }

    .toys{
        display: none;
    }

    .box{
        display: none;
    }

    .cards-select{
        display: none;
    }

    .selected-cards-container{
        display: none;
    }

    .dices{
        display: none;
    }

    .move-icon{
        display: none;
    }

    .zoom-tools{
        display: none;
    }
}

@media (max-width: 900px) {

    #react-jitsi-container{
        width: 150px!important;
        height: 100px!important;
    }
}

