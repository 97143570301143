.draw-modes-collapsed{
    display: none;
}

.draw-modes-expanded{
    position: absolute;
    top:0px;
    left: 58px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 0;
    background-color: #fff;
    border-top: 1px solid #F5F3F1;
    border-bottom: 1px solid #F5F3F1;
    border-right: 1px solid #F5F3F1;
    border-radius: 0px 12px 12px 0px;
}

.draw-modes-expanded svg{
    margin-right: 10px;
}

.draw-modes-expanded svg{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: none !important;
}

@media (max-height: 578px){
    .draw-modes-expanded{
        flex-direction: column;
        top: 40px;
        left: 0px;
        padding: 0;
        border-top: 0px;
        border-radius: 0px 0px 12px 12px;
    }
}