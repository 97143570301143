.toy-menu{
    transition: .4s ease all;
}

.toy-menu.expanded{
    overflow: scroll;
    position: absolute;
    padding: 10px;
    top: 0px;
    left: 65px;
    width: 120px;
    height: 60vh;
    background-color: #fff;
    border: 1px solid #F5F3F1;
    border-radius: 12px;
    scrollbar-width: none;
}

.toy-menu.expanded::-webkit-scrollbar{
    display: none;
}

.color-menu.expanded{
    overflow: scroll;
    position: absolute;
    padding: 10px;
    top: 0px;
    left: 65px;
    background-color: #fff;
    border: 1px solid #F5F3F1;
    border-radius: 12px;
}

.color-menu.expanded.hair{
    top: 115px;
}

.color-menu.expanded.tshirt{
    top: 160px;
}

.color-menu.expanded.pants{
    top: 200px;
}

.color-menu.expanded.face{
    top: 245px;
}

.color-menu.expanded.skin{
    top: 296px;
}

.color-menu.expanded .colors{
    display: flex;
    align-items: center;
}

.color-menu.expanded .colors svg{
   margin: 0 !important;
}

.faces-icons{
    display: flex;
    flex-direction: row;
}

.color-menu.expanded.face .faces-icons svg{
    margin-right: 10px !important;
}

.color-menu.expanded.face .faces-icons svg:last-of-type{
    margin-right: 0px !important;
}

.toy-menu.expanded img{
    width: 60%;
    margin: 20px;
}

.toy-menu.expanded .images{
   margin-top: -67px;
}


.left-menu-container .stairs-icon{
    margin-bottom: 20px !important;
}

@media (max-height: 578px){

    .left-menu-container .stairs-icon{
        margin-bottom: 5px !important;
    }

    .toy-menu.expanded{
        top: 50px;
        left: 0;
        width: 260px;
        height: 124px;
    }

    .toy-menu.expanded .images{
        display: flex;
    }

    .toy-menu.expanded img {
        max-width: 46px;
        width: auto;
        margin: 10px;
        margin-top: 76px;
    }

    .color-menu.expanded.hair {
        top: 45px;
        left: 84px;
    }

    .color-menu.expanded.tshirt {
        top: 45px;
        left: 118px;
    }
    .color-menu.expanded.pants {
        top: 45px;
        left: 158px;
    }
    .color-menu.expanded.face {
        top: 45px;
        left: 198px;
    }
    .color-menu.expanded.skin {
        top: 45px;
        left: 238px;
    }
}