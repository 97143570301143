.dashboard-content{
    position: relative;
    height: 95vh;
    margin: 20px 16px 0px 0px!important;
    padding: 50px 44px;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
    overflow-x: hidden;
}

.dashboard-content ion-header{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}


.dashboard-icon{
    margin-left: 15px;
}

.next-sessions-content{
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 30px;
}


.dashboard-content h3{
    font-size: 20px;
    margin-bottom: 20px;
}

.dashboard-content .sessions-table{
    margin: 20px 0;
    height: 74vh;
    overflow: scroll;
}

.dashboard-content .sessions-table p{
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.dashboard-content .session-row{
    display: flex;
    border-bottom: 1px solid #F5F3F1;
    padding: 6px;
    cursor: pointer;
}

.dashboard-content .session-row svg{
    width: 24px;
}

.dashboard-content .session-row .desktop-dashboard{
    display: flex;
}

.dashboard-content .session-row .date{
    width: 80px;
    margin-right: 12px;
}

.dashboard-content .session-row .date p{
    font-weight: 600;
}

.dashboard-content .session-row .hour{
    width: 80px;
    margin-right: 30px;
}

.dashboard-content .session-row .code{
    width: 100px;
    margin-right: 30px;
}

.dashboard-content .session-row .name{
    width: calc(100% - 204px);
    margin-right: 30px;
}

.dashboard-content .session-row .display{
    width: 24px;
}

.dashboard-content .sessions-table ion-grid:first-of-type{
    background-color: #F9F9F9;
    border-radius: 10px;
}

.dashboard-content .sessions-table ion-grid:first-of-type .session-row{
    background-color: #F9F9F9;
    border-radius: 10px;
    border-bottom: none !important;
    
}

.alert-session-dashboard{
    font-size: 16px;
    /* margin: 36px 0 45px 10px !important; */
}

.future-session-container{
    margin-top: 100px;
}

.link-open{
    display: flex;
    justify-content: space-between;
}

.link-url{
    position: relative;
}

.next-session-link{
    transition: .3s ease all;
    overflow: hidden;
}

.next-session-link.open{
    padding: 0 8px 15px;
    margin-top: 16px;
    max-height: 100px;
}

.next-session-link.close{
    padding: 0 8px 0px;
    margin-top: 0;
    max-height: 0px;
}

.dashboard-content .session-row ion-col{
    font-size: 14px;
    font-weight: 400;
}

.dashboard-content .session-row ion-col:first-of-type{
    font-weight: 600;
}

.next-session-link ion-text{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 12px;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;
    margin-right: 12px;
    border: 1px solid rgba(54, 54, 54, 0.1);
    border-radius: 5px;
}

.next-session-link .link{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.sessions-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.next-session-link .copy-icon{
    margin-left: 20px;
}

.next-session-link a{
    margin-right: 12px;
}


.next-session-link svg:hover{
    cursor: pointer;
}

.next-session-link svg:active{
    transform: scale(0.9)
}

.next-session-link .sessions-button .bin-icon{
    position: absolute;
    right: 5px;
    margin-left: 20px;
}

.next-session-link .button{
    margin: 0;
    margin-right: 30px;
}

.enter-button a{
    margin-top: 12px;
}

.sessions-card-buttons{
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.dashboard-content .modal{
    transition: .3s ease all;
    cursor: pointer;
}

.dashboard-content .modal:hover{
    background-color: rgb(239, 252, 237);
    border: .5px solid #E3E3E3;
}

.delete-modals-buttons{
    margin-top: 20px;
}

.delete-modals-buttons .button-tertiary{
    margin: 0px;
}

.version-code{
    margin-right: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.version-code p{
    font-size: 12px;
    color: #E5E5E5;
}

@media (max-width: 768px) {

    .dashboard-content {
        position: absolute;
        top: 104px;
        left: 8px;
        width: calc(100% - 13px);
        height: 85%;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        padding-bottom: 200px;
    }

    .dashboard-content ion-header{
        margin: 28px 0px 0px 5px;
    } 

    /* .dashboard-content h1 {
        margin-left: 14px !important;
    } */

    .dashboard-content ion-header h1{
        font-size: 22px;
        margin-left: 12px !important;
    }

    .dashboard-content ion-header ion-button{
        width: 172px;
    }

    .dashboard-content .session-row{
        margin: 0;
        padding: 0;
    }

    .dashboard-content .sessions-table {
        margin: 0;
        padding: 8px;
        overflow: visible;
    }

    .dashboard-content .session-row {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
    }

    .dashboard-content .session-row svg{
        width: 24px;
    }

    .dashboard-content .session-row .desktop-dashboard{
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
    }
    
    .dashboard-content .session-row .date{
        margin-right: 26px;
        margin-bottom: 4px;
    }
    
    .dashboard-content .session-row .date p{
        font-weight: 600;
    }
    
    .dashboard-content .session-row .hour{
        margin-right: 25px;
        margin-bottom: 4px;
    }
    
    .dashboard-content .session-row .code{
        margin-right: 32px;
        width: 60px;
    }
    
    .dashboard-content .session-row .name{
        margin-right: 20px;
    }

    .next-session-link .link {
        align-items: flex-start;
    }

    .version-code{
        top: 0;
        height: 0px;
    }
    
}

@media (max-width: 966px) {
    .next-session-link ion-text{
        margin-bottom: 20px;
    }

    .next-session-link.open {
        max-height: 115px;
    }

}

@media (max-width: 414px){

    .dashboard-content .sessions-table ion-grid:first-of-type .session-row {
        margin-bottom: 18px;
    }

    .next-session-link ion-text {
        width: 83vw;
        height: 42px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .link-open{
        display: block;
    }
    
    .sessions-card-buttons {
        margin-top: 18px;
        margin-left: 10px;
    }

    .delete-modals-buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .delete-modals-buttons .button-primary{
        margin: 0;
        margin-bottom: 20px;
    }

    .version{
        bottom: -45px;
    }

}


@media (max-width: 376px){

    .next-session-link .copy-icon {
        right: 28px;
    }
}

@media (max-height: 414px){

    .dashboard-content h3 {
        font-size: 18px;
    }

    .dashboard-content .sessions-table p {
        font-size: 12px;
    }

    .dashboard-content .sessions-table {
        margin: 20px 20px 20px 20px;
        height: auto;
    }

    .dashboard-content .sessions-table {
        padding: 14px;
    }

    .dashboard-content .session-row .date {
        margin-right: 0;
    }
    
    .dashboard-content .session-row .hour {
        margin-right: 0px;
    }

    .dashboard-content .session-row .name {
        margin-right: 68px;
        width: 65px;
    }

}