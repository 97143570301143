
.cards-select{
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: flex;
    height: 371px;
    background-color: #FFFFFF;
    border: 1px solid #F5F3F1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0px 20px;
    z-index: 24;
    width: calc(100vw - 20px);
    /*overflow: hidden;*/
}

.cards-select svg{
    position: absolute;
    z-index: 20;
    right: 20px;
    top: 12px;


    position: absolute;
    z-index: 20;
    right: 12px;
    top: 12px;
    width: 25px;
    height: 25px;
    transition: 0.3s;
    opacity: 0;
    cursor: pointer;
}

.cards-select svg{
    opacity: 1;
}

.cards-row{
    display: flex;
    position: relative;
    padding: 8px;
    padding-top: 36px;
}
.hide-by-zoom{
    display:none;
}

.cards-row-arrow{
    position: absolute;
    top:150px;
    left: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-color: #ffffff;
    transition: 0.5s;
    opacity: 0;
    color: #4d4c4c;
    line-height: 47px;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans';
}

.cards-select .cards-row-arrow{
    opacity: 1;
}

.cards-row-arrow.arr-right{
    left: auto;
    right: 0;
}
.scroll-content.ios-overflow{
    height: 100vh;
    top: calc(100% - 100vh + 11px);
}
.selected-cards-container{
    position: absolute;
    display: flex;
    z-index: 1;
    top: 109px;
    left: 50px;
    max-width: 910px;
    height: 554px;
    border: 1px dashed #79ce678f;
    width: calc(100% - 100px);
}

.selected-cards-container .card{
    width: 225px;
    height: 310px;
    padding: 4px 7px;
    transition: 0.3s;
}
.selected-cards-container .card:hover, .selected-cards-container .card.dragging{
    transition:none;
}

.selected-cards-container .card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.cards-select.hide{
    display: none;
}

.cards-row-overflow{
    overflow-y: scroll;
}

@media (max-height: 578px){

    .cards-select {
        height: 260px;
    }

    .cards-row {
        padding-top: 20px;
    }

    .cards-row .card {
        max-width: 146px;
    }

    .card .zoom{
        display: none;
    }

    .selected-cards-container .card img{
        width: 60%;
        height: 60%;
    }

}