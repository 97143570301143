.teddy-container{
    display: none;
    position: fixed;
    bottom: 10px;
    left: 10px;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid #F5F3F1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0px 20px;
    z-index: 24;
    width: calc(100vw - 20px);
    height: 450px;
    opacity: .9;
}

.teddy-container.hide{
    display: none;
}

.teddy-select{
    margin-top: 34px;
    margin-left: 68px;
}

.teddy-figure{
    position: relative;
}

.teddy-container svg:active{
    transform: scale(0.9)
}

.teddy-container .close-icon{
    z-index: 1000000001;
}

.teddy-select svg{
    position: relative;
}
#teddy-selected{
    top:150px;
    left:150px;
}
#teddy, 
#teddy-id{
    position: absolute;
    overflow: hidden;
    width: 302px;
    height: 357px;
    top: -75px;
    left: calc(68% - 150px); 
}

#teddy>div{
    position: absolute;
    top:0;
    left: 0;
    transform: scale(0.3);
    transform-origin: top left;
}

#teddy-id>div{
    position: absolute;
    top:0;
    left: 0;
    transform: scale(0.3) !important;
    transform-origin: top left;
}

#teddy img{
    width: 100%;
}

#teddy-id img{
    width: 100%;
}

#teddy-element-body{
    overflow: scroll;
    transition: .6s ease-in-out all;
    scroll-behavior: smooth;
    z-index: 10;
    grid-row-start: 1;
    grid-column-start: 1;
}

#teddy-element-ears{
    overflow: scroll;
    transition: .6s ease-in-out all;
    scroll-behavior: smooth;
    z-index: 9;
    grid-row-start: 1;
    grid-column-start: 1;
    position: relative;
}

#teddy-element-face{
    overflow: scroll;
    transition: .6s ease-in-out all;
    scroll-behavior: smooth;
    z-index: 10;
    grid-row-start: 1;
    grid-column-start: 1;
}

#teddy-element-paws{
    overflow: scroll;
    transition: .6s ease-in-out all;
    scroll-behavior: smooth;
    z-index: 10;
    grid-row-start: 1;
    grid-column-start: 1;
}

#teddy-body,
#teddy-face,
#teddy-ears,
#teddy-paws{
    width: 7168px;
    transition: 0.6s;
}

.done-button{    
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 12;
}

.teddy-created{
    position: relative !important;
    z-index: -11;
}

.teddy-message{
    z-index: 12;
    position: absolute;
}

.teddy-message p{
    position: absolute;
    top: 45px;
    left: 43px;
    width: 135px;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    text-align: center;
}

.teddy-message.think svg{
    transform: rotateY(180deg);
}
