
/*DASHBOARD*/

body {
    user-select: none; 
}
.session-timing{
    user-select: text;
}
#dashboard-container{
    display: flex;
    background-color: var(--background);
    height: 100vh;
}

ion-nav{
    background-color: var(--background);
}

.inner-scroll{
    padding: 0 !important;
}

.dashboard-content{
    position: relative;
    width: 70%;
    height: 84vh;
    margin: 20px 16px 0px 0px!important;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #F5F3F1;
    box-shadow: 0px 17px 42px rgba(0, 0, 0, 0.03);
}

.dashboard-content-none{
    display: none;
}
.button{
    height: 36px;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
    display: inline-block; 
    padding: 6px; 
    color: none;
    margin-bottom: 15px;
    text-transform: none;
    font-family: 'Open Sans';
    transition: .6s ease all;
}

.button-primary{
    background-color: #79CE67;
    color: #FFFFFF;
}

.button-secondary{
    color: #984FB2;
    background-color: transparent;
    border: 1px solid #984FB2;
}

.button-secondary:hover{
    color: #FFFFFF;
    background-color: #984FB2;
}

.button-tertiary{
    border: 1px solid #79CE67;
    color: #79CE67;
}

.button-tertiary:hover{
    background-color: #79CE67;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.button-accent {
    color: #FFFFFF;
    background-color: #984FB2;
}

.button-accent:hover{
    color: #984FB2;
    background-color: #FFFFFF;
    border: 1px solid #984FB2;
}


